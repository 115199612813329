export const environment = {
  isProduction: false,
  isStaging: false,
  isTest: true,
  isDevelopment: false,
  // doesn't work with hostname :-(
  apiUrl: 'https://test.plan.seetaltixi.ch/api/v1',
  // TODO: allow test.plan.seetaltixi.ch to access
  // gmaAPIKey: 'AIzaSyCpAAsTH1CLlk0S0grjjA3-wgYqjcX7IkA',
  gmaAPIKey: 'AIzaSyBqQTzISWkpBL9OdKg00onZFRlLvc8yAYE',
  gmaApiUrl: 'https://maps.googleapis.com/maps/api/js',
  sessionTimeout: 2 * 3600,  // timeout in seconds
  appName: 'Tixiplan©',
  appVersion: '0.9.0',
  appVersionDate: '29.03.2025',
  appStage: 'test',
  supportEmail: 'support@rabbit-it.ch',
  supportPhone: '+41 41 562 08 28',
}
